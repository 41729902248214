import React from "react"
import CountUp from 'react-countup';

export default function StudentCountUp(props) {

	return (
		<div>
			<CountUp end={props.count} separator="," duration={2} useEasing={true} /> 
		</div>
	)
}
