import React, { useState } from 'react';
import { htmlToText } from 'html-to-text';

export default function ReadMore(props) {
	const [expanded, setExpanded] = useState(false);
	const [text, setText] = useState(htmlToText(props.html));

	function expand(event) {
		event.preventDefault();
		setExpanded(true)
	}

	if (expanded || text.length < props.limit) {
		return (
			<div className="ReadMore">
				<div dangerouslySetInnerHTML={{__html: props.html}}></div>
			</div>
		)
	} else {
		return (
			<div className="ReadMore">
				{htmlToText(props.html, {limits: {maxInputLength: props.limit}})}... <a href="#" onClick={expand} className='branding'>Read more</a>
			</div>	
		)
	}
}