import React from "react"
import Countdown from 'react-countdown';

export default function PromotionCountdown(props) {
	let separator = props.separator;
	function days(dayCount) {
		if (dayCount > 0) {
			return (
				<span>
					<span className="count shadow-sm">
						{dayCount}d
					</span>
					{props.separator}
				</span>
			)
		} else {
			return null;
		}
	}

	return (
		<Countdown
		  date={new Date(props.date)}
		  intervalDelay={0}
		  precision={3}
		  renderer={props => (
		  		<span>
		  			{days(props.days)}
		  			<span className="count shadow-sm">
		  				{props.hours}h
		  			</span>
		  			{separator}
		  			<span className="count shadow-sm">
		  				{props.minutes}m
		  			</span>
		  			{separator}
		  			<span className="count shadow-sm">
		  				{props.seconds}s
		  			</span>
		  		</span>
			  )
		  }
		/>
	)
}
