import React, { useState, useEffect } from 'react';
import Plyr from 'plyr';

export default function VideoPlayer(props) {
	const [player, setPlayer] = useState(null);
	const [speed, setSpeed] = useState(1);
	const rateTransitions = {
		0.75: 1,
		1: 1.25,
		1.25: 1.5,
		1.5: 1.75,
		1.75: 2,
		2: 0.75
	};

	const playerId = `video-player-${props.id}`

	useEffect(() => {

		let options = {
			background: true,
			"controls": ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'fullscreen']
		}

		const player = new Plyr(`#${playerId}`, options);
		setPlayer(player);
		player.toggleCaptions(false)

		player.on('ratechange', event => {
			setSpeed(player.config.speed.selected);
		});
	}, []);



	function changeRate(event) {
		event.preventDefault();
		player.speed = rateTransitions[player.speed];
		setSpeed(rateTransitions[player.speed]);
	}

	function rewind(event) {
		event.preventDefault();
		player.rewind(30);
	}

	function forward(event) {
		event.preventDefault();
		player.forward(30);
	}

	function controls() {
		return (
			<div className="row mt-3 mb-3 d-none d-md-flex">
			  <div className="col">
			    <a href="#" className="btn btn-light btn-block btn-sm" role="button" id="video-speed" aria-pressed="true" onClick={changeRate}>
			    	Speed: <span>{speed}</span>x
			    </a>
			  </div>
			  <div className="col">
			    <a href="#" className="btn btn-light btn-block btn-sm" role="button" id="video-backward" aria-pressed="true" onClick={rewind}>
			    	<i className="fas fa-undo text-muted"></i>30
			    </a>
			  </div>
			  <div className="col">
			    <a href="#" className="btn btn-light btn-block btn-sm" role="button" id="video-forward" aria-pressed="true" onClick={forward}>
			    	30 <i className="fas fa-redo text-muted"></i>
			    </a>
			  </div>
			</div>
		)
	}

	function video() {
		if (props.provider === 'vimeo') {
			return <div id={playerId} data-plyr-provider="vimeo" data-plyr-embed-id={props.vimeo_id} poster={props.poster} />;
		} else {
			return (
				<video width="100%" height="600" controls controlsList="nodownload" id={playerId} preload="metadata" playsInline poster={props.poster}>
				  <source src={props.url} type="video/mp4" />
				</video>
			)
		}
	}

	return (
		<div className="VideoPlayer">
			{video()}
			{props.controls && controls()}
		</div>
	)
}