import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Prism from "prismjs";

export default function CssColorGenerator() {
  const [color, setColor] = useState({ hex: "#885df1", rgb: { r: 136, g: 93, b: 241 } });
  const [alpha, setAlpha] = useState(1);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, [color, alpha]);

  function handleColorChange(color) {
    setColor(color);
  }

  function handleAlphaChange(event) {
    setAlpha(parseFloat(event.target.value));
  }

  function colorCSS() {
    let css = "";
    if (alpha === 1) {
      css += color.hex;
    } else {
      css += `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${alpha})`;
    }
    

    return css;
  }

  function generatedCode() {
    return `background: ${colorCSS()};\ncolor: ${colorCSS()};`;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <h3>Options</h3>
          <div className="generator-section">
            <div className="generator-section-group">
              <label>Pick a color</label>
              <SketchPicker
                color={color}
                disableAlpha={true}
                onChangeComplete={handleColorChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Color opacity <span>{alpha * 100}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={alpha}
                onChange={handleAlphaChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <div
              className="generator-preview mb-2"
              style={{ background: colorCSS() }}
            ></div>

            <div className="center generator-text-preview" style={{ color: colorCSS() }}>
              SheCodes Workshops
            </div>
          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                	Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>
              	{copy()}
              </div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
