import React from "react"
import Countdown from 'react-countdown';

export default function ContestCountdown(props) {
	return (
		<Countdown
		  date={new Date(props.date)}
		  intervalDelay={0}
		  precision={3}
		  renderer={props => (
		  		<span>
		  			<span className="count">
		  				{props.days}
		  				<span>
		  					Days
		  				</span>
		  			</span>
		  			
		  			<span className="count">
		  				{props.hours}
		  				<span>
		  					Hours
		  				</span>
		  			</span>
		  			
		  			<span className="count">
		  				{props.minutes}
		  				<span>
		  					Minutes
		  				</span>
		  			</span>
		  			
		  			<span className="count">
		  				{props.seconds}
		  				<span>
		  					Seconds
		  				</span>
		  			</span>
		  		</span>
			  )
		  }
		/>
	)
}
