import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

export default function AthenaRequestFollowUpForm(props) {
  const [question, setQuestion] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [parentId, setParentId] = useState(props.parentId);
  const formRef = useRef(null);
  const [questionFormat, setQuestionFormat] = useState('text');

  function updateQuestion(event) {
    setQuestion(event.target.value);
  }

  const updateCode = React.useCallback((value) => {
    setQuestion(value);
  }, []);

  function showTextEditor() {
    setQuestionFormat('text');
  }

  function showCodeEditor() {
    setQuestionFormat('code');
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (valid()) {
      setDisabled(true);
      axios.post('/athena_requests.json', {
        'athena_request': {
          parent_id: parentId,
          question: question,
          question_format: questionFormat,
          question_type: 'follow_up'
        }
      })
      .then(function (response) {
        props.addFollowUpRequest(response.data);
        setQuestion('');
        setParentId(response.request.parentId);
        setDisabled(false);
        formRef.current.scrollIntoView();
      })
      .catch(function (error) {
        alert("Something went wrong.. please try again")
        setDisabled(false);
      });
    }
    
  }

  function valid() {
    return !disabled && question.length > 0 && question.length < 1000
  }

  function followUpPlaceholder() {
    if (props.questionType === 'interview' || props.questionType === 'code') {
      return "Type your answer here";
    } else {
      return "Ask your follow up question here";
    }
  }

  function inputMenu() {
    if (props.questionType === 'interview' || props.questionType === 'test') {
      return (
        <div className="athena-follow-up-form-menu">
          <div onClick={showTextEditor} className={questionFormat === 'text' ? 'active' : null}>
            Text
          </div>
          <div onClick={showCodeEditor} className={questionFormat === 'code' ? 'active' : null}>
            Code
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  function inputRows() {
    if (props.questionType === 'interview' || props.questionType === 'test') {
      return 2;
    } else {
      return 1;
    }
  }

  function handleKeyDown(event) {
    if ((event.metaKey || event.ctrlKey) && event.which === 13) {
      handleSubmit(event);
    }
  }

  function inputElement() {
    if (questionFormat == 'code') {
      return <CodeMirror
            value={question}
            placeholder="Enter your code here"
            theme={oneDark}
            height="auto"
            width="100%"
            minHeight="120px"
            viewportmargin="Infinity"
            extensions={[javascript({ jsx: true })]}
            onChange={updateCode}
          />
    } else {
        return <TextareaAutosize className="form-control text required" minLength="20px" maxLength="1000" required="required" rows={inputRows()} placeholder={followUpPlaceholder()}  value={question} onChange={updateQuestion} minRows={inputRows()} onKeyDown={handleKeyDown} />;
    }
  }

  function hint() {
    if (props.questionType === 'interview' || props.questionType === 'test') {
      return (
        <span>
          You can stop the {props.questionType} by typing "<span className="prefill" onClick={() => setQuestion("stop please")}>stop please</span>". If you don't know the answer, simply type "<span className="prefill" onClick={() => setQuestion("I don't know")}>I don't know</span>" 
        </span>
      );
    } else {
      return null;
    }
  }

  return (
    <form ref={formRef} className="athena-follow-up-form" action="/athena_requests" method="POST">
      <div>
        {inputMenu()}
        {inputElement()}
        <div className="d-flex justify-content-between">
          <div className="form-text text-muted">{hint()}</div>
          <div className="d-flex">
            <div>
              <div className="form-shortcut me-2">Ctrl/Cmd + Enter</div>
            </div>
            <input type="submit" className="btn btn-branding mt-2" value="Reply" disabled={!valid()} onClick={handleSubmit} />
          </div>
        </div>
      </div>
      <input type="hidden" name="athena_request[question_type]" value="follow_up" />
      <input type="hidden" name="athena_request[parent_id]" value={parentId} />
      <input type="hidden" name="athena_request[question]" value={question} />
    </form>
  );
}
