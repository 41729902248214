import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GradientPicker } from "react-linear-gradient-picker";
import Prism from "prismjs";

export default function CssGradientGenerator() {
  const [type, setType] = useState("linear");
  const [copied, setCopied] = useState(false);
  const [angle, setAngle] = useState(90);
  const defaultPalette = [
    { offset: "0.00", color: "rgb(252, 115, 156)" },
    { offset: "1", color: "rgb(139, 102, 241)" },
  ]

  const [palette, setPalette] = useState(defaultPalette);

  const WrappedSketchPicker = ({ onSelect, ...rest }) => {
    return (
      <SketchPicker
        {...rest}
        presetColors={[]}
        disableAlpha={true}
        color={rgbToRgba(rest.color, rest.opacity)}
        onChange={(c) => {
          const { r, g, b, a } = c.rgb;
          onSelect(`rgb(${r}, ${g}, ${b})`, a);
        }}
      />
    );
  };

  const rgbToRgba = (rgb, a = 1) =>
    rgb.replace("rgb(", "rgba(").replace(")", `, ${a})`);

  useEffect(() => {
    Prism.highlightAll();
  }, [palette, angle, type]);

  function handleTypeChange(event) {
    setType(event.target.value);
  }

  function handleAngleChange(event) {
    setAngle(parseInt(event.target.value));
  }

  function backgroundCSS() {
    let css = `${type}-gradient`;
    css += "(";
    if (type === 'linear') {
      css += `${angle}deg, `;

    }
    palette.forEach((paletteColor, index) => {
      if (index !== 0) {
        css += ', '
      }
      css += `${paletteColor.color} ${parseInt(paletteColor.offset * 100)}%`;
    })
    css += ")";
    return css;

  }

  function generatedCode() {
    let code = `background: ${backgroundCSS()};`;

    return code;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  function angleInput() {
    if (type === 'linear') {
      return (
        <div className="generator-section-group">
          <label className="d-flex justify-content-between">
            Angle <span>{angle} degrees</span>
          </label>
          <input
            type="range"
            min="0"
            max="360"
            value={angle}
            onChange={handleAngleChange}
          />
        </div>
      );
    }
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <div className="d-flex justify-content-between">
            <h3>Options</h3>
          </div>
          <div className="generator-section">
            <div className="generator-section-group">
              <GradientPicker
                {...{
                  maxStops: 5,
                  paletteHeight: 32,
                  palette,
                  onPaletteChange: setPalette,
                }}
              >
                <WrappedSketchPicker />
              </GradientPicker>
            </div>

            <div className="generator-section-group">
              <label>Type</label>

              <select
                onChange={handleTypeChange}
                className="form-control select"
                value={type}
              >
                <option>linear</option>
                <option>radial</option>
              </select>
            </div>
            {angleInput()}
          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <div
              className="generator-preview"
              style={{ background: backgroundCSS() }}
            ></div>
          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                  Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>{copy()}</div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
