import React, { useState, useEffect  } from 'react';

export default function TextareaWithCountdown(props) {
  const [countdown, setCountdown] = useState(null);
  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    if ((props.minlength - value.length) < 0) {
      setCountdown("👍")  
    } else {
      setCountdown(`${value.length} / ${props.minlength}`)
    }
  }, [value]);

  function handleChange(event) {
    setValue(event.target.value)
  }

  return (
    <div className="textarea-with-countdown">
      <textarea 
        className="form-control is-valid text required" 
        rows={props.rows}
        minLength={props.minlength} 
        required="required" 
        aria-required="true" 
        name={props.name} 
        id={props.id}
        onChange={handleChange}
        value={value}
        ></textarea>
        <span className="textarea-with-countdown-count  badge badge-secondary">
          {countdown}
        </span>
    </div>
  );
}
