import React from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

 
export default function CalendarEvent(props) {
  return (
    <AddToCalendarButton
      name={props.name}
      options={['Google','Apple', 'Outlook.com']}
      description={props.description}
      location={props.location}
      startDate={props.startDate}
      endDate={props.endDate}
      buttonStyle='round'
      startTime={props.startTime}
      endTime={props.endTime}
      timeZone={props.timeZone}
    ></AddToCalendarButton>

  ) 
}
