import React from "react"

export default function Mailto(props) {
	function populate(event) {
		event.preventDefault();

		const subject = encodeURIComponent(document.querySelector(`#${props.subject}`).innerHTML.trim())
		const body = document.querySelector(`#${props.body}`).innerHTML.trim();

		if (props.app) {
			location.href = `mailto:${props.email}?subject=${subject}&body=${encodeURIComponent(body)}`;
		} else {
			const href = `https://app.frontapp.com/compose?mailto=mailto:${props.email}?subject=${subject}&body=${encodeURIComponent(body)}`;
			window.open(href, '_blank');
		}
	}
	
	return (
		<a href="#" className="Mailto" onClick={populate} className={props.classNames} target="_blank">
			{props.label}
		</a>
	)
}
