import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function Clipboard(props) {
	const [copied, setCopied] = useState(false);

	function handleCopy(event) {
		setCopied(true);
		alert("Copied 🙌");
	}


	return (
		<div className="Clipboard">
			<div className="Clipboard-content">
				{props.content} 
			</div>
			<CopyToClipboard text={props.content} onCopy={handleCopy}>
				<i className="fa fa-clipboard"></i>
			</CopyToClipboard>
		</div>
	);
}