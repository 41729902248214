import React from "react"

export default function HallOfFameStudent(props) {
	return (
		<div className="HallOfFame-student" key={props.student.id}>
			<a href={`students/${props.student.slug}`} target="_blank">
				<img src={props.student.certificate_image_url} className="img-fluid HallOfFame-student-certificate shadow" />
			</a>

			<div className="HallOfFame-student-details">
				<img src={props.student.avatar_url} className="HallOfFame-student-avatar rounded-circle" />
				<div className="HallOfFame-student-name">
				{props.student.first_name} {props.student.last_name}	
				</div>

				<div className="HallOfFame-student-nationality">
					{props.student.nationality_emoji} {props.student.nationality}
				</div>
			</div>
		</div>
		)
}