import React, { useState, useEffect, userCallback } from "react";


export default function RichEditor(props) {


  return (
    <div className='remirror-theme'>

    </div>
  );
}
