import React, { useState, useEffect } from "react"
import ReactCountdown from 'react-countdown';

export default function Countdown(props) {
	function renderer({ days, hours, minutes, seconds, completed }) {
		if (days < 10) days = `0${days}` 
		if (minutes < 10) minutes = `0${minutes}` 
		if (seconds < 10) seconds = `0${seconds}` 
		if (hours < 10) hours = `0${hours}` 


		return (
			<div className="countdown-items clearfix">
			<div className="countdown-item float-start">
				<div className="countdown-item-value">
					{days}
				</div>
				<div className="countdown-item-unit">
					Days
				</div>
			</div>
				<span className="countdown-separator float-start">:</span>
			<div className="countdown-item float-start">
				<div className="countdown-item-value">
					{hours}
				</div>
				<div className="countdown-item-unit">
					Hours
				</div>
			</div>
				<span className="countdown-separator float-start">:</span>
				<div className="countdown-item float-start ">
					<div className="countdown-item-value">
						{minutes}
					</div>
					<div className="countdown-item-unit">
						Minutes
					</div>
				</div>
				<span className="countdown-separator float-start">:</span>
				<div className="countdown-item float-start">
					<div className="countdown-item-value">
						{seconds}
					</div>
					<div className="countdown-item-unit">
						Seconds
					</div>
				</div>

			</div>
		);	
	};
	return (
		<div className="ExpressCountdown">
			<ReactCountdown
				date={new Date(props.time)}
				renderer={renderer}
				intervalDelay={100}
			/>
		</div>
	)
}