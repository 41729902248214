import React, { useEffect, useState } from "react";
import Prism from 'prismjs';

export default function Code(props) {

	useEffect(() => {
	  Prism.highlightAll();
	}, []);


	return (
		<code className={`language-${props.language}`}>
			{props.code}
		</code>
	)
}