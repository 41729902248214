import React, { useState, useEffect } from "react";
import axios from "axios";
import hljs from "highlight.js";
import Typed from 'react-typed';
import { MutatingDots } from 'react-loader-spinner'
import AthenaRequestFollowUps from './AthenaRequestFollowUps';
import AthenaRequestFollowUpForm from './AthenaRequestFollowUpForm';
import Pusher from 'pusher-js';

export default function AthenaRequestAnswer(props) {
  const [request, setRequest] = useState(props.request);
  const [state, setState] = useState(props.request.state);
  const [followUpRequests, setFollowUpRequests] = useState(props.request.children);
  const hideStatus = props.hideStatus || false;
  
  useEffect(() => {
    if (request.state !== "processed" && request.state !== "failed") {
      const pusher = new Pusher(pusherKey, { cluster: pusherCluster });
      let channel = pusher.subscribe(request.broadcast_channel);
      channel.bind('broadcast', function(response) {
        refresh();
        if (response === 'processed' || response === 'failed') {
          channel.unbind();
          pusher.disconnect();
        }
      });
      refresh();
    }
    hljs.highlightAll();
  }, [request]);

  function refresh() {
    axios.get(`/athena_requests/${props.request.id}.json`).then(function(response) {
      if (response.data.state !== request.state) {
        setRequest(response.data);
      }
    })
  }


  function upvote(event) {
    event.preventDefault();
    axios.patch(request.upvote_path).then((response) => {
      setRequest(response.data);
    });
  }

  function downvote(event) {
    event.preventDefault();
    axios.patch(request.downvote_path).then((response) => {
      setRequest(response.data);
    });
  }

  function answer() {
    if (request.state === "processed") {  
      if (state !== request.state && request.answer_as_markdown) {
        return <Typed
          strings={[request.answer_as_markdown]}
          showCursor={false}
          typeSpeed={0}
          onStringTyped={() => hljs.highlightAll()}
        />
       } else {
      	return <div dangerouslySetInnerHTML={{ __html: request.answer_as_markdown }} />;
       }
    } else {
      if (request.state === "failed") {
        return (
          <p>
            Your request could not be processed by Athena because the convesation is too long. Please, ask Athena a new question instead.
          </p>
        );
      } else {
        return <MutatingDots 
          height="100"
          width="100"
          color="#fd749c"
          secondaryColor= '#885df1'
          radius='12.5'
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
         /> ;
      }
    }
    setState(request.state);
  }

  function upvoteLink() {
    if (!request.downvoted) {
      if (request.upvoted) {
        return "👍";
      } else {
        return (
          <a
            onClick={upvote}
            href="#"
            className="text-decoration-none border p-1 rounded"
            title="Helpful answer"
          >
            👍 Helpful
          </a>
        );
      }
    }
  }

  function downvoteLink() {
    if (!request.upvoted) {
      if (request.downvoted) {
        return (
          <span>
            <a
              href={`https://www.google.com/search?q=${request.question}`}
              className="text-decoration-none border p-1 rounded ms-3"
              target="_blank"
            >
              Ask Google instead{" "}
              <i className="ms-1 fab fa-google text-muted"></i>
            </a>
          </span>
        );
      } else {
        return (
          <a
            onClick={downvote}
            href="#"
            className="text-decoration-none border p-1 rounded"
            title="Not helpful answer"
          >
            👎 Not helpful
          </a>
        );
      }
    }
  }

  function followUps() {
    if (followUpRequests.length > 0) {
      return <AthenaRequestFollowUps requests={followUpRequests} hideStatus={props.hideStatus} />;
    } else {
      return null
    }
  }

  function addFollowUpRequest(newFollowUpRequest) {
    setFollowUpRequests(followUpRequests.concat(newFollowUpRequest))
  }

  function followUpForm() {
    if (!hideStatus && followUpRequests.length === 0 && request.state === 'processed') {
      return <AthenaRequestFollowUpForm parentId={request.id} addFollowUpRequest={addFollowUpRequest} questionType={request.root_question_type} />;
    } else {
      return null
    }
  }

  function feedback() {
    if (!hideStatus) {
      return (
        <span className="d-none d-md-inline ms-3">
          <span className="me-2">{upvoteLink()}</span>
          <span>{downvoteLink()}</span>
        </span>
      )
    } else {
      return null;
    }
  }

  function status() {
    if (request.state === "processed") {
      return (
        <div className="text-muted d-none d-lg-block">
          Answered in {request.processing_time} seconds
        </div>
      );
    }
  }

  return (
    <div>
      <div className="athena-request athena-request-answer">
        <div className="d-flex justify-content-between">
          <h3>
            🤖 SheCodes Athena says:
          </h3>
          <div className="d-flex">
            {status()}
            {feedback()}
          </div>
        </div>
        {answer()}
      </div>
      {followUps()}
      {followUpForm()}

    </div>
  );
}
