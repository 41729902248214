import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Prism from "prismjs";

export default function CssBorderGenerator() {
  const [color, setColor] = useState({ hex: "#885df1", rgb: { r: 136, g: 93, b: 241 } });
  const [width, setWidth] = useState(10);
  const [style, setStyle] = useState("solid");
  const [alpha, setAlpha] = useState(1);
  const [radius, setRadius] = useState(20);
  const [copied, setCopied] = useState(false);
  const styles =  ['dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', 'none', 'hidden'];

  useEffect(() => {
    Prism.highlightAll();
  }, [color, width, style, alpha, radius]);

  function handleColorChange(color) {
    setColor(color);
  }

  function handleRadiusChange(event) {
    setRadius(parseInt(event.target.value));
  }

  function handleAlphaChange(event) {
    setAlpha(parseFloat(event.target.value));
  }

  function handleWidthChange(event) {
    setWidth(parseInt(event.target.value));
  }

  function handleStyleChange(event) {
    setStyle(event.target.value);
  }

  function borderCSS() {    
    let css = `${width}px ${style} `;

    if (alpha === 1) {
      css += `${color.hex}`;
    } else {
      css += `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${alpha})`;
    }

    return css;
  }

  function generatedCode() {
    let code = "";
    if (width > 0) {
      code = `border: ${borderCSS()};`;
      if (radius > 0) {
        code += `\nborder-radius: ${radius}px;`;
      }
    } 
    return code;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <h3>Options</h3>
          <div className="generator-section">
            <div className="generator-section-group">
              <label>Border Color</label>
              <ChromePicker
                color={color}
                disableAlpha={true}
                onChangeComplete={handleColorChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Opacity <span>{alpha * 100}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={alpha}
                onChange={handleAlphaChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Width <span>{width}px</span>
              </label>
              <input
                type="range"
                min="0"
                max="20"
                value={width}
                onChange={handleWidthChange}
              />
            </div>
            <div className="generator-section-group">
              <label>
                Style
              </label>

              <select onChange={handleStyleChange} className="form-control select" value={style}>
              {styles.map(function(style, index) {
                return <option key={index}>{style}</option>;

              })}
              </select>
            </div>

            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Radius <span>{radius}px</span>
              </label>
              <input
                type="range"
                min="0"
                max="150"
                value={radius}
                onChange={handleRadiusChange}
              />
            </div>

          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <div
              className="generator-preview"
              style={{ border: borderCSS(), borderRadius: `${radius}px` }}
            ></div>
          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                	Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>
              	{copy()}
              </div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
