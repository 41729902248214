import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js'
import Snippet from "./Snippet";
import Prism from 'prismjs';
import axios from 'axios';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-python';
import Masonry from 'masonry-layout';

export default function Cheatsheet(props) {
	const [keyword, setKeyword] = useState("");
	const [results, setResults] = useState([]);
	const [theme, setTheme] = useState(props.theme);
	const fuseOptions = { keys: ['name', 'language', 'snippet', 'description', 'notes', 'category_name'], includeScore: true};
	const cheatsheetSnippets = new Fuse(props.cheatsheet.snippets, fuseOptions);

	useEffect(() => {
	  Prism.highlightAll();
	  let masonryGrid = document.querySelector(".masonry-grid");
	  if (masonryGrid) {
	  	new Masonry(masonryGrid);
	  }
	}, [results]);

	useEffect(() => {
		setResults(cheatsheetSnippets.search(keyword));	
	}, [keyword]);

	function search(event) {
		event.preventDefault();
	}

	function changeKeyword(event) {
		setKeyword(event.target.value)
	}

	function toggleTheme(event) {
		if (theme == 'light') {
			setTheme('dark');	
			axios.get(`${props.updateThemePath}?snippet_theme=dark`)
		} else {
			setTheme('light');	
			axios.get(`${props.updateThemePath}?snippet_theme=light`)
		}
		
	}

	function menu() {
		return (
			<div className="d-none d-lg-block">
				<div className="row mb-3">
					<div className="col-md-9">
					{about()}
					</div>
					<div className="col-md-3 d-flex justify-content-end align-self-end">
						<div className="text-white p-0 bg-dark pt-2 pe-3 ps-3 pb-2 rounded mb-3 shadow-sm">
							<input type="checkbox" onChange={toggleTheme} defaultChecked={props.theme === 'dark'} id="theme" /> <label htmlFor="theme">Dark Theme</label>
						</div>
					</div>
				</div>
				<div className=" bg-white pt-3 ps-3 pe-3 pb-1 shadow-sm rounded-5 mb-3">
					<span className="me-2">
						Snippets categories:
					</span>
					<a href={props.cheatsheet.path} className="review-filter">
						<span className={`badge badge-secondary m-0 me-2 ${props.categorySlug === null ? "active" : ''}`}>
								All
						</span>
					</a>
					{props.cheatsheet.categories.map((category, index) => {
						return (
							<a href={category.path} key={index} className="review-filter">
								<span className={`badge badge-secondary m-0 me-2 mb-2 ${category.slug === props.categorySlug ? "active" : ''}`}>
										{category.name}
								</span>
							</a>
						)
					})}
				</div>
			</div>
		)
	}

	function snippets() {
		if (keyword) {
			return (
				<div className="row masonry-grid">
					{results.map((result, index) => {
						return (
							<div key={index} className="col-lg-6">
								<Snippet snippet={result.item} theme={theme} />
							</div>
						)
					})}
				</div>
			)
		} else {
			return (
				<div className="row masonry-grid">
					{props.cheatsheet.categories.map((category, index) => {
						return (
							<div key={index} className={`${props.snippetSlug || props.categorySlug ? 'col-md-12' : 'col-lg-6'} ${props.categorySlug && props.categorySlug !== category.slug ? 'd-none' : ''}`}>
								<h2>{category.name}</h2>
								{category.snippets.map((snippet, index) => {
									return (
										<div key={index} className={`${props.snippetSlug && props.snippetSlug !== snippet.slug ? 'd-none' : ''}`}>
											<Snippet snippet={snippet} theme={theme} />
										</div>
									)
								})}
							</div>
						)	
					})}
				</div>
			)
		}
	}

	function about() {
		if (props.about) {
			return (
				<div>
					<div dangerouslySetInnerHTML={{__html: props.about}}></div>
				</div>
			)
		}
	}

	function navigation() {
		if (props.navigation) {
			return (
				<div>
					<div dangerouslySetInnerHTML={{__html: props.navigation}}></div>
				</div>
			)
		}
	}

	return (
		<div className="Cheatsheet">
			<div className="row">
			  <div className="col-lg-3">
			    <div className="form-group sticky-top bg-white p-3 shadow-sm rounded-5">
				    <form onSubmit={search}>
				    	<input type="search" onChange={changeKeyword} className="form-control p-4 pe-2 ps-2" placeholder="Search cheatsheets.." autoFocus={true} />
				    	<input type="submit" value="Search" className="btn btn-secondary rounded block btn-sm block w-100 mt-2 d-none" />
				    </form>
				    <div className="d-none d-lg-block">
				    	{navigation()}
				    </div>
			   	</div>
			  </div>
				<div className="col-lg-9">

					{menu()}
					{snippets()}
				</div>
			</div>

		</div>
	)
}