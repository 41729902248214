import React, { useState, useEffect } from "react"
import ReactCountdown from 'react-countdown';
import Carousel from 'react-bootstrap/Carousel'
import moment from 'moment'

export default function Countdown(props) {
	const [percentage, setPercentage] = useState(0);
	const [complete, setComplete] = useState(false);
	const [initialTime, setInitialTime] = useState(Date.now());
	const [audio] = useState(new Audio('/audio/glive.mp3'));
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
	    playing ? audio.play() : audio.pause();
	  },
	  [playing]
	);

	useEffect(() => {
	  audio.addEventListener('ended', () => setPlaying(false));
	  return () => {
	    audio.removeEventListener('ended', () => setPlaying(false));
	  };
	}, []);

	const toggle = () => setPlaying(!playing)

	function renderer({ days, hours, minutes, seconds, completed }) {
		if (days < 10) days = `0${days}` 
		if (minutes < 10) minutes = `0${minutes}` 
		if (seconds < 10) seconds = `0${seconds}` 
		if (hours < 10) hours = `0${hours}` 


		return (
			<div className="countdown-items clearfix">
			<div className="countdown-item float-start">
				<div className="countdown-item-value">
					{days}
				</div>
				<div className="countdown-item-unit">
					Days
				</div>
			</div>
				<span className="countdown-separator float-start">:</span>
			<div className="countdown-item float-start">
				<div className="countdown-item-value">
					{hours}
				</div>
				<div className="countdown-item-unit">
					Hours
				</div>
			</div>
				<span className="countdown-separator float-start">:</span>
				<div className="countdown-item float-start ">
					<div className="countdown-item-value">
						{minutes}
					</div>
					<div className="countdown-item-unit">
						Minutes
					</div>
				</div>
				<span className="countdown-separator float-start">:</span>
				<div className="countdown-item float-start">
					<div className="countdown-item-value">
						{seconds}
					</div>
					<div className="countdown-item-unit">
						Seconds
					</div>
				</div>

			</div>
		);	
	};

	function onComplete() {
		setComplete(true);
		audio.pause();
		if (props.onComplete) {
			props.onComplete();
		}

	}

	function updatePercentage() {
		setPercentage((100 / (props.time - initialTime)) * (Date.now() - initialTime))
	}

	function testimonial(text, limit = null) {
		if (text.length > limit) {
			return text.slice(0, limit) + "...";
		} else {
		 return text;
		}
	}

	function date() {
		if (props.date) {
			return (
				<div className="countdown-date">
					{moment(props.date * 1000).format('dddd, MMMM D, YYYY')}
				</div>
			);
		} else {
			return <div className="countdown-date" />;
		}
	}

	function count() {
		if (props.count && props.count >= 100) {
			return (
				<div className="countdown-student-count">
					You are about to watch SheCodes Express with {props.count} other students
				</div>
			);
		} else {
				return (
					<div className="countdown-student-count">
						You are about to watch SheCodes Express with {props.count + 115} other students
					</div>
				);
		}
	}

	function soundCheck() {
		if (props.soundCheck) {
			return (
				<div className="countdown-sound-check">
					<button onClick={toggle} className="btn btn-secondary">{playing ? "👍" : "🔉 Sound check"}</button>
				</div>
			)
		}
	}


	return (
		<div className="countdown">
			{soundCheck()}
			<div className="countdown-inner">
				<img className="countdown-logo img-fluid" src={props.logo}/>
				<div className="countdown-ticker">
					<ReactCountdown
						date={new Date(props.time)}
						renderer={renderer}
						intervalDelay={100}
						onTick={updatePercentage}
						onComplete={onComplete}
					/>
				</div>
				{date()}
				{count()}


				<div  className="countdown-progress">
					<div 
						className="countdown-progress-inner" 
						style={{width: `${percentage}%`}} 
					/>
			</div>
				<div className="countdown-messages">
					<Carousel controls={false} indicators={false} interval={3000} pause={false} touch={false}>
						{props.messages.map((message, index) => {
							return (
								<Carousel.Item key={index}>
									<p>
										{message}								
									</p>
								</Carousel.Item>
							)
						})}
					</Carousel>
				</div>
			<div className="countdown-testimonials">
			<Carousel controls={false} indicators={false} interval={7000} pause={false} fade={true} touch={false}>
					{props.students.map((student) => {
						return (
							<Carousel.Item key={student.id}>
							<div className="countdown-testimonial">
								<div className="row">
									<div className="col-sm-3">
										<span className="countdown-testimonial-avatar-wrapper">
											<img src={student.avatar_url} className="img-fluid" />
										</span>
									</div>
									<div className="col-sm-9">
									<p >
										<strong>
										⭐️⭐️⭐️⭐️⭐️ 5/5 - Highly recommended
										</strong>
										{testimonial(student.testimonial, 250)}
									</p>
									<footer>
										<strong>
										 {student.first_name} {student.last_name}, 
										</strong> SheCodes Alumni
									</footer>
									</div>
								</div>
			       	</div>
			       	</Carousel.Item>
		       	)
					})}    
			</Carousel>
			</div>
			</div>
		</div>
	)
}