import React, { useRef, useEffect } from 'react';

export default function CodeInput(props) {
	const input = useRef(null);

	useEffect(() => {
		CodeMirror.fromTextArea(input.current, {
			lineNumbers: true,  
			theme: 'dracula',
			mode:  "javascript"
		});
	}, []);

	return (
		<div className="CodeInput">
			<textarea ref={input} required={props.required} autoFocus={props.autofocus} name="challenge_submission[code]" defaultValue={props.value} />
		</div>
	)
}