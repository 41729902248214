import React, { useEffect, useState } from "react";
import hljs from "highlight.js";
import Editor from "react-simple-code-editor";

export default function LiveEditor(props) {
  const [code, setCode] = useState("");
  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");

  function updateHtml(event) {
    event.preventDefault();
    setHtml(event.target.value);
  }

  function updateCss(event) {
    event.preventDefault();
    setCss(event.target.value);
  }

  return (
    <div className="LiveEditor">
      <h1>LiveEditor</h1>
      <Editor
        value={html}
        placeholder={"Enter your code"}
        autofocus={true}
        required={true}
        onValueChange={(html) => setHtml(html)}
        highlight={(html) => hljs.highlight(html, { language: "html" }).value}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
        }}
      />

      <Editor
        value={css}
        placeholder={"Enter your code"}
        autofocus={true}
        required={true}
        onValueChange={(css) => setCss(css)}
        highlight={(css) => hljs.highlight(css, { language: "css" }).value}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
        }}
      />
      <div className="LiveEditor-preview">
        <style>{css}</style>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}
