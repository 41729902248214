import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import hljs from "highlight.js";

export default function SimpleSnippet(props) {
  const [copied, setCopied] = useState(false);

  const theme = props.theme || "dark";

  useEffect(() => {}, []);

  function copy() {
    let label = "Copy code";
    if (copied) {
      label = "Code copied";
    }

    return (
      <CopyToClipboard text={props.code} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding">{label}</button>
      </CopyToClipboard>
    );
  }

  function code() {
    return props.code;
  }

  return (
    <div className={`SimpleSnippet SimpleSnippet--${theme}`}>
      <div className="SimpleSnippet-header d-flex justify-content-end">
        {copy()}
      </div>
      <div className={`Snippet Snippet--${theme}`}>
        <pre className="Snippet-code">
          <code>{code()}</code>
        </pre>
      </div>
    </div>
  );
}
