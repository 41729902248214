import React, { useState } from "react"
import axios from 'axios';
import Loader from './Loader';
import HallOfFameStudent from './HallOfFameStudent';
import InfiniteScroll from 'react-infinite-scroller';


export default function HallOfFame(props) {
	const [students, setStudents] = useState(props.students);
	const [page, setPage] = useState(2);

	function showStudents(response) {
		setPage(page + 1);
		setStudents([...students, ...response.data])
	}

	function loadMore() {
		axios.get(`${props.load_more_url}?page=${page}`).then(showStudents)
	}

	return (
		<InfiniteScroll
		    pageStart={1}
		    loadMore={loadMore}
		    hasMore={students.length !== props.total_count}
		    loader={<Loader />}
		>
			<div className="HallOfFame">
				{props.results.map((student, index) => {
					return (
						<HallOfFameStudent student={student} key={index} />
					)
				})}
				{students.map((student, index) => {
					return (
						<HallOfFameStudent student={student} key={index} />
					)
				})}
			</div>
		</InfiniteScroll>
	)
}
