import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Prism from "prismjs";

export default function CssImageFilterGenerator(props) {
  const [blur, setBlur] = useState(0);
  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [grayscale, setGrayscale] = useState(0);
  const [hueRotate, setHueRotate] = useState(0);
  const [invert, setInvert] = useState(0);
  const [saturate, setSaturate] = useState(100);
  const [sepia, setSepia] = useState(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, [
    blur,
    brightness,
    contrast,
    grayscale,
    hueRotate,
    invert,
    saturate,
    sepia,
  ]);

  function handleBlurChange(event) {
    setBlur(parseInt(event.target.value));
  }

  function handleBrightnessChange(event) {
    setBrightness(parseInt(event.target.value));
  }

  function handleContrastChange(event) {
    setContrast(parseInt(event.target.value));
  }

  function handleGrayscaleChange(event) {
    setGrayscale(parseInt(event.target.value));
  }

  function handleHueRotateChange(event) {
    setHueRotate(parseInt(event.target.value));
  }

  function handleInvertChange(event) {
    setInvert(parseInt(event.target.value));
  }

  function handleSaturateChange(event) {
    setSaturate(parseInt(event.target.value));
  }

  function handleSepiaChange(event) {
    setSepia(parseInt(event.target.value));
  }

  function filterCSS() {
    let css = "";
    if (blur !== 0) {
      css += ` blur(${blur}px)`
    }

    if (brightness !== 100) {
      css += ` brightness(${brightness}%)`
    }

    if (contrast !== 100) {
      css += ` contrast(${contrast}%)`
    }

    if (grayscale !== 0) {
      css += ` grayscale(${contrast}%)`
    }

    if (hueRotate !== 0) {
      css += ` hue-rotate(${hueRotate}deg)`
    }


    if (invert !== 0) {
      css += ` invert(${invert}%)`
    }

    if (saturate !== 100) {
      css += ` saturate(${saturate}%)`
    }

    if (sepia !== 0) {
      css += ` sepia(${sepia}%)`
    }

    return css;
  }

  function generatedCode() {
    return `filter: ${filterCSS()};`;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <h3>Options</h3>
          <div className="generator-section">
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Blur <span>{blur}px</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={blur}
                onChange={handleBlurChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Brightness <span>{brightness}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="300"
                value={brightness}
                onChange={handleBrightnessChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Contrast <span>{contrast}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="300"
                value={contrast}
                onChange={handleContrastChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Grayscale <span>{grayscale}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={grayscale}
                onChange={handleGrayscaleChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Hue-Rotate <span>{hueRotate}deg</span>
              </label>
              <input
                type="range"
                min="0"
                max="360"
                value={hueRotate}
                onChange={handleHueRotateChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Invert <span>{invert}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={invert}
                onChange={handleInvertChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Saturate <span>{saturate}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="300"
                value={saturate}
                onChange={handleSaturateChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Sepia <span>{sepia}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={sepia}
                onChange={handleSepiaChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <img
              className="generator-image-preview img-fluid"
              src={props.imagePath}
              style={{ filter: filterCSS() }}
            />
          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                  Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>{copy()}</div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
