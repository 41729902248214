import React from 'react';

export default function CatLoader(props) {
	return (
		<div className="Loader center">
			<span className="Loader-emoji">
			  😹
			</span>
			<span className="Loader-emoji">
			  😻
			</span>
			<span className="Loader-emoji">
				🙀
			</span>
			<span className="Loader-emoji">
			  😼
			</span>
			<span className="Loader-emoji">
				😿
			</span>
			<span className="Loader-emoji">
			  😸
			</span>
		</div>
	)
}