import React, { useState } from 'react';

export default function Help(props) {
  const [visiblePopup, setVisiblePopup] = useState(false);

  function hidePopup(event) {
    event.preventDefault();
    setVisiblePopup(false);
    
  }

  function togglePopup(event) {
    event.preventDefault();

    setVisiblePopup(!visiblePopup);
  }

  function icon() {
    if (visiblePopup) {
      return <i className="fas fa-angle-up" />;
      
    } else {
      return <i className="fas fa-comments" />;
    }
  }

  function popup() {
    if (visiblePopup) {
      return (
        <div className="help-popup">
          <div className="help-popup-header d-flex justify-content-between">
            <img src="/logo.png" alt="" />
            <i className="fas fa-angle-down" onClick={hidePopup} />
          </div>
          <div className="help-popup-main">
            <h4>
              We’re here to help! 👋
            </h4>
            <p>
              If you have any questions, feel free to fill out our Contact Form. We reply to all messages!
            </p>
            <a href="/contact" className="btn btn-primary btn-block shadow-sm">Contact  Us</a>
            <p>
              New to coding? Join our 60-minute free coding class!
            </p>
            <a href="/free-class" className="btn btn-primary btn-pink btn-block shadow-sm">Join our FREE class</a>
            <p>
              If you’re a student or unemployed, you can apply for a discount here: <br />

              👉 <a href="/discount">www.shecodes.io/discount</a>
              <br />
              <br />
              You can also contact us at 
              <br />
              <span className="text-muted">
                team@shecodes.io!
              </span>
            </p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="help">
      {popup()}
      
      <div className="help-navigation d-flex" onClick={togglePopup}>
        <span className="d-none d-md-block">
        Need help?
        </span>
        {icon()}
      </div>
    </div>
    )  



  

}
