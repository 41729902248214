import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Prism from "prismjs";

export default function CssTextShadowGenerator() {
  const [color, setColor] = useState({ hex: '#e510f5', rgb: { r: 229, g: 16, b: 245 } });
  const [offset, setOffset] = useState({ x: 5, y: 5 });
  const [alpha, setAlpha] = useState(0.4);
  const [blur, setBlur] = useState(10);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, [color, offset, alpha, blur]);

  function handleColorChange(color) {
    setColor(color);
  }

  function handleOffsetXChange(event) {
    setOffset({ x: event.target.value, y: offset.y });
  }

  function handleOffsetYChange(event) {
    setOffset({ x: offset.x, y: event.target.value });
  }

  function handleBlurChange(event) {
    setBlur( parseInt(event.target.value));
  }

  function handleAlphaChange(event) {
    setAlpha(event.target.value);
  }

  function textShadowCSS() {
    let css = "";
    css += `${offset.x}px ${offset.y}px `;
    if (blur !== 0) {
      css += `${blur}px `;
    }
    css += `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${alpha})`;

    return css;
  }

  function generatedCode() {
    return `text-shadow: ${textShadowCSS()};\ncolor: ${color.hex};`;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <h3>Options</h3>
          <div className="generator-section">
            <div className="generator-section-group">
              <label>Shadow Color</label>
              <ChromePicker
                color={color}
                disableAlpha={true}
                onChangeComplete={handleColorChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Horizontal offset <span>{offset.x}px</span>
              </label>
              <input
                type="range"
                min="-100"
                max="100"
                value={offset.x}
                onChange={handleOffsetXChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Vertical offset <span>{offset.y}px</span>
              </label>
              <input
                type="range"
                min="-100"
                max="100"
                value={offset.y}
                onChange={handleOffsetYChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Opacity <span>{alpha * 100}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={alpha}
                onChange={handleAlphaChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Blur <span>{blur}px</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={blur}
                onChange={handleBlurChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <div
              className="center generator-text-preview"
              style={{ textShadow: textShadowCSS(), color: color.hex }}>
              SheCodes Workshops
            </div>
          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                	Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>
              	{copy()}
              </div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
