import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Prism from "prismjs";

export default function CssBoxShadowGenerator() {
  const [color, setColor] = useState({ rgb: { r: 0, g: 0, b: 0 } });
  const [offset, setOffset] = useState({ x: 10, y: 10 });
  const [alpha, setAlpha] = useState(0.2);
  const [radius, setRadius] = useState({ blur: 20, spread: 0 });
  const [inset, setInset] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, [color, offset, alpha, radius, inset]);

  function handleColorChange(color) {
    setColor(color);
  }

  function handleOffsetXChange(event) {
    setOffset({ x: event.target.value, y: offset.y });
  }

  function handleOffsetYChange(event) {
    setOffset({ x: offset.x, y: event.target.value });
  }

  function handleSpreadRadiusChange(event) {
    setRadius({ spread: parseInt(event.target.value), blur: radius.blur });
  }

  function handleBlurRadiusChange(event) {
    setRadius({ blur: parseInt(event.target.value), spread: radius.spread });
  }

  function handleAlphaChange(event) {
    setAlpha(event.target.value);
  }

  function handleInsetChange(event) {
    setInset(event.target.checked);
  }

  function boxShadowCSS() {
    let css = "";
    if (inset) {
      css += "inset ";
    }
    css += `${offset.x}px ${offset.y}px `;
    if (radius.blur !== 0) {
      css += `${radius.blur}px `;
    }
    if (radius.spread !== 0) {
      css += `${radius.spread}px `;
    }
    css += `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${alpha})`;

    return css;
  }

  function generatedCode() {
    return `box-shadow: ${boxShadowCSS()};`;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <h3>Options</h3>
          <div className="generator-section">
            <div className="generator-section-group">
              <label>Shadow Color</label>
              <ChromePicker
                color={color}
                disableAlpha={true}
                onChangeComplete={handleColorChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Horizontal offset <span>{offset.x}px</span>
              </label>
              <input
                type="range"
                min="-100"
                max="100"
                value={offset.x}
                onChange={handleOffsetXChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Vertical offset <span>{offset.y}px</span>
              </label>
              <input
                type="range"
                min="-100"
                max="100"
                value={offset.y}
                onChange={handleOffsetYChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Opacity <span>{alpha * 100}%</span>
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={alpha}
                onChange={handleAlphaChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Blur <span>{radius.blur}px</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={radius.blur}
                onChange={handleBlurRadiusChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Spread <span>{radius.spread}px</span>
              </label>
              <input
                type="range"
                min="0"
                max="100"
                value={radius.spread}
                onChange={handleSpreadRadiusChange}
              />
            </div>
            <label>
              <input
                type="checkbox"
                checked={inset}
                onChange={handleInsetChange}
              />
              Inset (inside shadow)
            </label>
          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <div
              className="generator-preview"
              style={{ boxShadow: boxShadowCSS() }}
            ></div>
          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                	Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>
              	{copy()}
              </div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
