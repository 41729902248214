import React from "react"
import Carousel from 'react-bootstrap/Carousel'

export default function Testimonials(props) {
	return (
		<div className="Testimonials">
		<Carousel indicators={false}>
			{props.testimonials.map((testimonial, index) => {
				return (
					<Carousel.Item key={index}>
					<div className="Testimonial">
						<div className="row">
							<div className="col-md-3">
								<div className="Testimonial-avatar ">
									<img src={testimonial.avatar_url} className="img-fluid" />
								</div>
							</div>
							<div className="col-md-9 ps-md-5">
								<div className="Testimonial-name">
									{testimonial.name}
								</div>
								<div className="Testimonial-company-title">
									<a target="_blank" href={testimonial.linkedin_url}>{testimonial.linkedin_title}</a> at {testimonial.company_name}
								</div>
								<div className="Testimonial-shecodes">
									<a target="_blank" className="text-pink" href={testimonial.shecodes_url}>{testimonial.shecodes_title}</a>
								</div>
								<div className="Testimonial-quote">
									"{testimonial.quote}"
								</div>
								<img src={testimonial.company_logo_url} className="Testimonial-company-logo img-fluid" />
							</div>
						</div>
					</div>

					</Carousel.Item>
				)
			})}
			</Carousel>
		</div>
		
	)
}
