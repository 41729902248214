import React, { useState } from 'react';
import axios from 'axios';

export default function RemoteButton(props) {
  const [label, setLabel] = useState(props.label);
  const [disabled, setDisabled] = useState(false);

  function handleClick(event) {
    event.preventDefault();
    setDisabled(true);
    axios
      .patch(props.path)
      .then(handleResponse)
      .catch(handleError)
  }

  function handleError(error) {
    alert("Something went wrong..")
  }

  function handleResponse(response) {
    if (response.status == 200) {
      setLabel(props.doneLabel)
    } else{
      alert("Something went wrong..")
    }
  }

  if (props.link) {
    return (
      <a href="#" className={props.classNames} onClick={handleClick} disabled={disabled}>
        {label}
      </a>
    );
  } else {
    return (
      <button className={props.classNames} onClick={handleClick} disabled={disabled}>
        {label}
      </button>
    );
  }

}
