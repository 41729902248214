import Rails from '@rails/ujs';
import $ from 'jquery';
import 'slick-carousel';
import { Tooltip, Toast, Carousel } from 'bootstrap';
import { iframeResizer } from "iframe-resizer";
import Headroom from "headroom.js/dist/headroom";
import ClipboardJS from 'clipboard/dist/clipboard';
import CountUp from 'react-countup';
import Masonry from 'masonry-layout';

require("@rails/actiontext");
let Trix = require("trix");
let componentRequireContext = require.context("components", true);
let ReactRailsUJS = require("react_ujs");

Rails.start();
ReactRailsUJS.useContext(componentRequireContext);

// Headroom
new Headroom(document.querySelector("body")).init();

// Tooltips
let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl)
})

// Toast
let toastElList = [].slice.call(document.querySelectorAll('.toast'))
let toastList = toastElList.map(function (toastEl) {
	const toast = new Toast(toastEl)
	toast.show();
})

new ClipboardJS('.clipboard');

let clipboard = new ClipboardJS('.clipboard-with-confirmation');
if (clipboard) {
	clipboard.on('success', function(e) {
		e.clearSelection();
	  alert("Copied 🙌")
	  event.preventDefault();
	});
}

let clipboardLabel = new ClipboardJS('.clipboard-with-label-confirmation');
if (clipboardLabel) {
	clipboardLabel.on('success', function(e) {
		e.clearSelection();
		let content = e.trigger.textContent;
	  e.trigger.textContent = 'Copied!';
	  let interval = setInterval(timer, 1000);
	  function timer() {
      e.trigger.textContent = content;
      clearInterval(interval);
	  }
	  event.preventDefault();
	});
}

let trustpilot = document.getElementById("trustpilot");

if (trustpilot && window.Trustpilot) {
	window.Trustpilot.loadFromElement(trustpilot);
}

let masonryGrid = document.querySelector(".masonry-grid");
if (masonryGrid) {
	new Masonry(masonryGrid);
}

iFrameResize(
	{
		checkOrigin: false,
		autoResize: false,
		sizeWidth: true,
		heightCalculationMethod: "documentElementScroll"
	},
	".iframe-resize"
);

$(document).ready(function () {
	document.body.classList.add("js-loaded");
	let slick = document.querySelector(".slider-wrapper");

	if (slick) {
		$(".slider-wrapper").slick({
			speed: 500,
			dots: false,	
			responsive: [
				{
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		        infinite: true,
		        arrows: false,
		        dots: true
		      }
		    }
			]
		});
	}

	$(".trix-content a").click(function(e) {
	  $(this).attr("target","_blank");
	});
});