import React, { useEffect, useState } from "react";
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

export default function JsonObject(props) {

	useEffect(() => {
	  Prism.highlightAll();
	}, []);


	return (
		<div className="Snippet">
			<pre className="Snippet-code">
				<code className="language-javascript">
					{JSON.stringify(props.code, null, 2)}
				</code>
			</pre>
		</div>
	)
}