import React from 'react';
import Fuse from 'fuse.js'
import Cheatsheet from './Cheatsheet'
 
export default function Cheatsheets(props) {
  return (
    <div className="cheatsheets">
      {props.cheatsheets.map((cheatsheet, index) => {
      	return (
      		<div key={index}>
      			<Cheatsheet cheatsheet={cheatsheet} />
      		</div>
      	)
      })}
    </div>
  ) 
}
