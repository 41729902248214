import React, { useState } from "react";
import Cookies from 'js-cookie';

export default function CookieBanner(props) {
	const [visible, setVisible] = useState(true);
	window.dataLayer = window.dataLayer || [];

	function gtag() {
	    dataLayer.push(arguments);
	}

	function approve(event) {
		event.preventDefault();
		setVisible(false);
		gtag('consent', 'update', {
		   'ad_user_data': 'granted',
		   'ad_personalization': 'granted',
		   'ad_storage': 'granted',
		   'analytics_storage': 'granted',
		   'functionality_storage': 'granted',
		   'personalization_storage': 'granted',
		   'security_storage': 'granted'
		 });
		Cookies.set('allow_cookies_v3', true, { expires: 365 });
	}

	function reject(event) {
		event.preventDefault();
		Cookies.set('allow_cookies_v3', false);
		gtag("consent", "default", {
		    ad_personalization: "denied",
		    ad_storage: "denied",
		    ad_user_data: "denied",
		    analytics_storage: "denied",
		    functionality_storage: "denied",
		    personalization_storage: "denied",
		    security_storage: "granted",
		    wait_for_update: 500,
		});
		gtag("set", "ads_data_redaction", true);
		gtag("set", "url_passthrough", true);
		setVisible(false);
	}

	if (visible) {
		return (
			<div className="cookie-banner row justify-content-between">
			  <p>
			    We use cookies to improve your experience and for marketing. Read our <a href="/cookie" target="_blank">cookie policy</a>.
			  </p>
			  <div className="d-flex justify-content-center justify-content-lg-start">
			  	<a onClick={approve} href="/" className="btn btn-primary block btn-sm me-2 shadow-sm">Accept All</a>
			  	<a onClick={reject} className="btn btn-secondary block btn-sm">Decline</a>
			  	<a href="/cookie" className="btn text-branding  d-none d-sm-block btn-sm">Read more</a>
			  </div>
			</div>
		)
	} else {
		return null;
	}
}