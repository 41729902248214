import React, { useState, useEffect } from "react";
import AthenaRequestAnswer from './AthenaRequestAnswer';

export default function AthenaRequestFollowUps(props) {

  function formattedQuestion(request) {
    if (request.question_format === 'code') {
      return (
        <div>
          <div>
          👩‍💻 Code provided
          </div>
          <pre><code>{request.question}</code></pre>
        </div>
      )
    } else {
      return (
        <p>👩‍💻 {request.question}</p>
      )
    }
  }

  return (
    <div className="athena-follow-ups">
        {props.requests.map((request, index) => {    
          return (
            <div key={index}>
              <div id={`question-${request.id}`} className="athena-request athena-request-question">
                
                {formattedQuestion(request)}
              </div>
              <AthenaRequestAnswer request={request} hideStatus={props.hideStatus} /> 
            </div>
          )
        })}
    </div>
  );
}
