import React, { useRef, useEffect } from 'react';
import hljs from 'highlight.js';

export default function Markdown(props) {
	const markdownEl = useRef(null);

	hljs.configure({
		tabReplace: "    ",
		languages: ["html", "css", "javascript"]
	});

	useEffect(() => {
		markdownEl.current.querySelectorAll("code,pre").forEach(block => {
			block.innerHTML = hljs.highlightAuto(block.innerText).value;
		});
	}, []);

	return (
		<div className="markdown-body" ref={markdownEl}>
			<div dangerouslySetInnerHTML={{__html: props.html}}></div>
		</div>
	)

}