import React, { useEffect, useState } from "react";
import hljs from 'highlight.js';
import Editor from 'react-simple-code-editor';


export default function CodingEditor(props) {
	const [code, setCode] = useState(props.code);

	return (
		<Editor
      value={code}
      name={props.name}
      placeholder={props.placeholder}
      autofocus={props.autofocus}
      required={props.required}
      minLength={props.min_length}
      maxLength={props.max_length}
      onValueChange={code => setCode(code)}
      highlight={code => hljs.highlightAuto(code).value}
      padding={10}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
      }}
    />
	)
}