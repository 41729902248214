import React, { Component } from 'react';
import axios from 'packs/axios'

export default class ShowcaseVote extends Component {
	constructor(props) {
		super(props);
		this.votePath = `/cohorts/${this.props.cohortId}/projects/${this.props.submissionId}/vote`;
		this.state = {
			stats: props.stats
		};
	}

	vote = (event, format) => {
		event.preventDefault();
		axios.patch(`${this.votePath}?format=${format}`);
		this.setState(prevState => ({
			stats: {
				...prevState.stats,
				[format]: this.state.stats[format] ? this.state.stats[format] + 1 : 1
			}
		}))
	}

	_action = (format, emoji) => {
		const count = this.state.stats[format] ? <small>{this.state.stats[format]}</small> : <small>0</small>;
		
		return (
			<li className="clearfix" onClick={(event) => this.vote(event, format)}>
				<span>{emoji}</span>{count}
			</li>
		)
	}

	render() {
		return (
			<ul className="showcase-vote">
				{this._action('great', '👍')}
				{this._action('wow', '🤩')}
				{this._action('love', '❤️')}
			</ul>
		);
	}
}

