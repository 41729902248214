import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Prism from "prismjs";

export default function CssTransformrGenerator(props) {
  const [translate, setTranslate] = useState({x: 0, y: 0});
  const [skew, setSkew] = useState({x: 0, y: 0});
  const [scale, setScale] = useState({x: 1, y: 1});
  const [rotate, setRotate] = useState({x: 0, y: 0, z: 0});
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, [
    translate, skew, scale, rotate
  ]);


  function handleTranslateXChange(event) {
    setTranslate({x: parseInt(event.target.value), y: translate.y});
  }  

  function handleTranslateYChange(event) {
    setTranslate({x: translate.x, y: parseInt(event.target.value)});
  }  

  function handleSkewXChange(event) {
    setSkew({x: parseInt(event.target.value), y: skew.y});
  }  

  function handleSkewYChange(event) {
    setSkew({x: skew.x, y: parseInt(event.target.value)});
  }  

  function handleScaleXChange(event) {
    setScale({x: parseFloat(event.target.value), y: scale.y});
  }  

  function handleScaleYChange(event) {
    setScale({x: scale.x, y: parseFloat(event.target.value)});
  }  

  function handleRotateXChange(event) {
    setRotate({x: parseInt(event.target.value), y: rotate.y, z: rotate.z});
  }  

  function handleRotateYChange(event) {
    setRotate({x: rotate.x, y: parseInt(event.target.value), z: rotate.z});
  }  

  function handleRotateZChange(event) {
    setRotate({x: rotate.x, y: rotate.y, z: parseInt(event.target.value)});
  }  

  function transformCSS() {
    let css = ""
    
    if (rotate.x !== 0) {
      css += ` rotateX(${rotate.x}deg)`;
    }

    if (rotate.y !== 0) {
      css += ` rotateY(${rotate.y}deg)`;
    }

    if (rotate.z !== 0) {
      css += ` rotateZ(${rotate.z}deg)`;
    }

    if (scale.x !== 1 || scale.y !== 1) {
      css += ` scale(${scale.x}, ${scale.y})`;
    }

    if (skew.x !== 0 || skew.y !== 0) {
      css += ` skew(${skew.x}deg, ${skew.y}deg)`;
    }

    if (translate.x !== 0 || translate.y !== 0) {
      css += ` translate(${translate.x}px, ${translate.y}px)`;
    }

    return css;
  }

  function generatedCode() {
    let css = `transform: ${transformCSS()};`;
   
    return css;
  }

  function copy() {
    let label = "Copy";
    if (copied) {
      label = "Copied";
    }

    return (
      <CopyToClipboard text={generatedCode()} onCopy={() => setCopied(true)}>
        <button className="btn btn-sm btn-branding-outline ms-2">
          {label}
        </button>
      </CopyToClipboard>
    );
  }

  return (
    <div className="BoxShadow">
      <div className="row">
        <div className="col-md-4">
          <h3>Options</h3>
          <div className="generator-section">
            <h4>Rotation</h4>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                X <span>{rotate.x}deg</span>
              </label>
              <input
                type="range"
                min="0"
                max="360"
                value={rotate.x}
                onChange={handleRotateXChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Y <span>{rotate.y}deg</span>
              </label>
              <input
                type="range"
                min="0"
                max="360"
                value={rotate.y}
                onChange={handleRotateYChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Z <span>{rotate.z}deg</span>
              </label>
              <input
                type="range"
                min="0"
                max="360"
                value={rotate.z}
                onChange={handleRotateZChange}
              />
            </div>
            <h4>Scale</h4>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                X <span>{scale.x}</span>
              </label>
              <input
                type="range"
                min="0"
                max="3"
                step="0.01"
                value={scale.x}
                onChange={handleScaleXChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Y <span>{scale.y}</span>
              </label>
              <input
                type="range"
                min="0"
                max="3"
                step="0.01"
                value={scale.y}
                onChange={handleScaleYChange}
              />
            </div>

            <h4>Skew</h4>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                X <span>{skew.x}deg</span>
              </label>
              <input
                type="range"
                min="0"
                max="360"
                value={skew.x}
                onChange={handleSkewXChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Y <span>{skew.y}deg</span>
              </label>
              <input
                type="range"
                min="0"
                max="360"
                value={skew.y}
                onChange={handleSkewYChange}
              />
            </div>
            <h4>Translate</h4>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                X <span>{translate.x}px</span>
              </label>
              <input
                type="range"
                min="-100"
                max="100"
                value={translate.x}
                onChange={handleTranslateXChange}
              />
            </div>
            <div className="generator-section-group">
              <label className="d-flex justify-content-between">
                Y <span>{translate.y}px</span>
              </label>
              <input
                type="range"
                min="-100"
                max="100"
                value={translate.y}
                onChange={handleTranslateYChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h3>Preview</h3>
          <div className="generator-section">
            <div
              className="generator-preview"
              style={{ transform: transformCSS() }}
            >
              SheCodes Workshops
            </div>

          </div>
          <div className="generator-section">
            <div className="d-flex justify-content-between">
              <div>
                <h4>🎉 Generated code</h4>
                <div className="text-muted">
                  Apply this CSS to the element you want to style.
                </div>
              </div>
              <div>{copy()}</div>
            </div>
            <div className="Snippet mt-3">
              <pre className="Snippet-code">
                <code className="language-css">{generatedCode()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
