import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

export default function Radio(props) {
	const [radio, setRadio] = useState(props.radio);
	const [started, setStarted] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [energyLevel, setEnergyLevel] = useState(props.energy_level);
	const [sort, setSort] = useState(props.sort);
	const [volume, setVolume] = useState(props.volume);
	const [label, setLabel] = useState('Ready to play');
	const [tracks, setTracks] = useState(props.tracks);
	const [userTracks, setUserTracks] = useState(props.user_tracks);
	const radioPlayer = useRef(null);
	const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

	useEffect(() => {
		if (currentTrackIndex === 0 && tracks.length) {
			radioPlayer.current.src = tracks[0].url;
		} else {
			setCurrentTrackIndex(0);
		}
		if (!playing) {
			radioPlayer.current.pause();
		}
	}, [tracks]);

	useEffect(() => {
		if (tracks.length) {
	    radioPlayer.current.src = tracks[currentTrackIndex].url;
	    if (!playing) {
	    	radioPlayer.current.pause();
	    }

		} else {
			if (playing) {
				radioPlayer.current.pause()
			}
		}

	}, [currentTrackIndex]);

	useEffect(() => {
		load()
	}, [energyLevel, radio, sort]);

	useEffect(() => {
		radioPlayer.current.volume = volume;
		axios.patch(`/radios/volume?volume=${volume}`)
	}, [volume]);



	function play() {
		if (started) {
			radioPlayer.current.play();	
		} else {
			radioPlayer.current.load();
			radioPlayer.current.play();	
			setStarted(true);
			axios.patch("/radios/listen")
		}
		setPlaying(true);
		setLabel("Now Playing")
	}

	function loadTracks(response) {
		setTracks(response.data)
	}

	function load() {
		axios.get(`/radios/api?energy_level=${energyLevel}&radio=${radio}&radio_sort=${sort}`).then(loadTracks)
	}

	function changeVolume(event) {
		setVolume(event.target.value / 100)
	}

	function pause() {
		radioPlayer.current.pause();
		setLabel("Paused")
		setPlaying(false);
	}

	function skip() {
		next();
		axios.patch(`/radios/${tracks[currentTrackIndex].id}/skip`)
	}

	function like() {
		if (!liked()) {
			setUserTracks([...userTracks, tracks[currentTrackIndex].id]);
			axios.patch(`/radios/${tracks[currentTrackIndex].id}/like`)
		}
	}

	function unlike() {
		if (liked()) {
			let newUserTracks = userTracks.filter(userTrack => userTrack !== tracks[currentTrackIndex].id)
			setUserTracks(newUserTracks);
			axios.patch(`/radios/${tracks[currentTrackIndex].id}/unlike`)
		}
	}	

	function next() {
		if (currentTrackIndex + 1 === tracks.length) {
			setCurrentTrackIndex(0);
			
		} else {
			setCurrentTrackIndex(currentTrackIndex + 1);
		}
	}

	function trackListen() {
		axios.patch(`/radios/${tracks[currentTrackIndex].id}/track_listen`)
	}

	function liked() {
		return userTracks.includes(tracks[currentTrackIndex].id)
	}



	function likeControl() {
		if (props.user_signed_in) {
			if (liked()) {
				return (
					<span onClick={unlike} className="Radio-controls-save Radio-controls-save--saved" />
				)
			} else {
				return (
					<span onClick={like} className="Radio-controls-save" />
				)
			}

		} else {
			return (
				<a data-bs-toggle="tooltip" data-placement="top" title="You need to login to save this track" href={props.login_path} className="Radio-controls-save" target="_blank" />
			)
		}
	}

	function controls() {
		return (
			<div>
				{radios()}
				<div className="Radio-controls">
					{playing && <button onClick={pause} className="Radio-controls-pause" />}
					{!playing && <button onClick={play} className="Radio-controls-play" />}
					{likeControl()}
					<span onClick={skip} className="Radio-controls-skip" />
					<input type="range" min={1} max={100}  value={volume * 100} onChange={changeVolume} className="Radio-controls-volume" />
				</div>
			</div>
		)
	}

	function savedTracksFilter() {
		if (props.user_signed_in && userTracks.length > 0) {
			return (
				<li onClick={() => setRadio('saved')} className={radio === 'saved' ? 'selected' : null}>
					<div>
						💜 Saved
					</div>
				</li>
			) 
		} else {
			return null;
		}
	}

	function radios() {
		return (
			<div className="Radio-filters">
				<ul className="Radio-filters-list Radio-filters-list--stations">
					<li onClick={() => setRadio('energy')} className={radio === 'energy' ? 'selected shadow-sm' : null}>
						<div>
							💻 Coding <span>station</span>
						</div>
					</li>
					<li onClick={() => setRadio('exotic')} className={radio === 'exotic' ? 'selected shadow-sm d-none' : 'd-none'}>
						<div>
							☕️ Coffee Break
						</div>
					</li>
					<li onClick={() => setRadio('calm')} className={radio === 'calm' ? 'selected shadow-sm' : null}>
						<div>
							🧘‍♀️ Calm 
						</div>
					</li>
					<li onClick={() => setRadio('space')} className={radio === 'space' ? 'selected shadow-sm' : null}>
						<div>
							🪐 Space 
						</div>
					</li>
					{savedTracksFilter()}
				</ul>
			</div>
		)
	}

	function sortOptions() {
		return (
			<div className="Radio-filters">
				<ul className="Radio-filters-list Radio-filters-list--secondary">
					<li onClick={() => setSort('shuffle')} className={sort === 'shuffle' ? 'selected' : null}>
						<div>
							<i className="fas fa-random"></i> Shuffle
						</div>
					</li>
					<li onClick={() => setSort('popular')} className={sort === 'popular' ? 'selected' : null}>
						<div>
							<i className="fas fa-fire-alt"></i> Trending
						</div>
					</li>
					<li onClick={() => setSort('recent')} className={sort === 'recent' ? 'selected' : null}>
						<div>
							<i className="far fa-star"></i> Recent
						</div>
					</li>
				</ul>
			</div>
		)
	}

	function filters() {
		if (radio == 'energy') {
			return (
				<div className="Radio-filters Radio-filters-list--secondary">
					<p>
						🌡 Energy level
					</p>
					<ul className="Radio-filters-list">
						<li onClick={() => setEnergyLevel('low')} className={energyLevel === 'low' ? 'selected' : null}>
							<span>
								Low energy
							</span>
						</li>
						<li onClick={() => setEnergyLevel('medium')} className={energyLevel === 'medium' ?  'selected' : null}>
							<span>
								Medium energy
							</span>
						</li>
						<li onClick={() => setEnergyLevel('high')} className={energyLevel === 'high' ? 'selected' : null}>
							<span>
								High energy
							</span>
						</li>
						<li onClick={() => setEnergyLevel('all')} className={energyLevel === 'all' ? 'selected' : null}>
							<span>
								All tracks
							</span>
						</li>						
					</ul>

				</div>
			)
		}
	}

	function explicitLabel() {
		if (tracks[currentTrackIndex].explicit) {
			return (
				<small className="Radio-label">
					🙀 explicit
				</small>
			)
		} else {
			return null;
		}
	}

	function trackCover() {
		if (playing) {
			return (
				<img src={tracks[currentTrackIndex].album_image_url} className="Radio-track-image Radio-track-image--playing img-fluid" />
			)
		} else {
			return (
				<img src={tracks[currentTrackIndex].album_image_url} className="Radio-track-image img-fluid" />
			)
		}
	}

	function track() {
		if (tracks.length && tracks[currentTrackIndex]) {
			return (
				<div>
					<div className="Radio-track row">
						<div className="col-md-3">
							{trackCover()}
						</div>
						<div className="col-md-9 Radio-track-info">
							<a href={tracks[currentTrackIndex].spotify_url} target="_blank" className="Radio-track-spotify" />
							<small className="Radio-label">
								📻 {label}
							</small>
							<small className="Radio-label">
								👩‍💻 {props.listeners} listening
							</small>
							{explicitLabel()}
							<h2>
								{tracks[currentTrackIndex].name}
							</h2>
							<h3>
								by {tracks[currentTrackIndex].artists}
							</h3>		
						</div>
					</div>
					{controls()}
				</div>
			)
		} else {
			return (
				<div>
					<p className="center">
						No tracks to play, select an energy level
					</p>
					{radios()}
				</div>
			)
		}
	}

	return (
		<div className={`Radio-player Radio-player--${radio} shadow`}>
			{track()}
			{sortOptions()}
			{false && filters()}
			<audio onEnded={next} onLoadedData={trackListen} ref={radioPlayer} controls={false} id="radio" autoPlay={true} /> 
		</div>
	)
	
}