import React, { useState, useRef, useEffect } from "react";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import TextareaAutosize from 'react-textarea-autosize';
import CreatableSelect from 'react-select/creatable';


export default function AthenaRequestForm(props) {
  const [questionType, setQuestionType] = useState(props.questionType);
  const [disabled, setDisabled] = useState(props.errorMessage && props.errorMessage.length > 0);
  const [question, setQuestion] = useState('');
  const [notice, setNotice] = useState('');
  const [warning, setWarning] = useState('');
  const [note, setNote] = useState('');
  const urlPattern = new RegExp('[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)', 'i');
  const placeholders = {
    'question': "Ask me anything about code - there are no wrong questions!",
    'fix': "Type or paste broken code here",
    'explain': "Type or paste code to be explained",
    'code': "Enter the instructions of your code"
  }

  const languageOptions = props.languages.map(language => {
    return { value: language, label: language };
  });

  const positionOptions = props.positions.map(position => {
    return { value: position, label: position };
  });

  function valid() {
    if (questionType === 'interview' || questionType === 'test') {
      return !disabled;
    } else {
      return !disabled && question.length > 0 && question.length < 1000;
    }
  }

  function showErrorMessage() {
    disabled && alert(props.errorMessage);
  }

  function updateQuestion(event) {
    setQuestion(event.target.value);

    if (urlPattern.test(event.target.value)) {
      setWarning('Please note that Athena is unable to read URLs for now')
    } else {
      setWarning('');
    }
  }

  const updateCode = React.useCallback((value) => {
    if (value.length > 1000) {
      setNotice(`Character limit exceeded, include only relevant sections of your code ${value.length}/1000`);
    } else {
      setQuestion(value);
      setNotice('');
    }
  }, []);

  function hint() {
    if (notice.length) {
      return (<div className="hint hint--danger">{notice}</div>);
    } else {
      if (note.length) {
        return (<div className="hint">{note}</div>);
      } else {
        if (warning.length) {
          return (<div className="hint hint--warning">{warning}</div>);
        } else {
          return null;
        }
      }
    }
  }


  function textInput() {
    if (questionType === 'question' || questionType === 'code') {
      return <TextareaAutosize className="form-control text required" minLength="10" maxLength="1000" required="required" rows={3} placeholder={placeholders[questionType]} disabled={disabled} value={question} onChange={updateQuestion} minRows={4} />;
    } else {
      if (questionType === 'interview') {
        return (
          <div className="athena-request-input-group">
            <h4>
              Practice your skills, ask Athena to interview you
            </h4>
           <div className="row">
             {/* bug in react select which forces to have this */}
             <select className="d-none" />
             <div className="col-md-6">
               <label htmlFor="athena-request-position">
                 Job position you're interviewing for
               </label>
               <CreatableSelect 
                 options={positionOptions} 
                 name="athena_request[position]" 
                 id="athena-request-position" 
                 defaultValue={positionOptions[0]} 
                 isClearable={false}  
               />
             </div>
             <div className="col-md-6">
               <label htmlFor="athena-request-experience">
                 Expererience required for the job
               </label>
               <select name="athena_request[experience]" id="athena-request-experience">
                 <option value="junior">Junior - No professional experience yet</option>
                 <option value="intermediate">Intermediate - 1 to 3 years experience</option>
                 <option value="senior">Senior - 3+ years experience</option>
               </select>
             </div>

           </div>
            <div>
            <label htmlFor="athena-request-job-descriptom">
              Enter the job description requirements: <span class="text-muted">optional, 1,000 characters max</span>
            </label>
              <TextareaAutosize className="form-control text required border rounded" maxLength="1000" rows={3} disabled={disabled} minRows={3} name="athena_request[job_description]" />
           </div>
          </div>
        )
      } else  {
        if (questionType === 'test') {
        return (
          <div className="athena-request-input-group">
            <h4>
              Practice your skills, ask Athena to test you
            </h4>
            <div className="row">
              
              <div className="col-md-6">
                <label htmlFor="athena-request-language">
                  Coding language or framework you want to be tested
                </label>
                <CreatableSelect  
                  options={languageOptions} 
                  name="athena_request[language]" 
                  id="athena-request-language" 
                  defaultValue={languageOptions[0]} 
                  isClearable={false}  
                />
              
              </div>
              <div className="col-md-6">
                <label htmlFor="athena-request-experience">
                  Your professional experience with it
                </label>
                <select name="athena_request[experience]" id="athena-request-experience">
                  <option value="junior">Junior - No professional experience yet</option>
                  <option value="intermediate">Intermediate - 1 to 3 years experience</option>
                  <option value="senior">Senior - 3+ years experience</option>
                </select>
              </div>
            </div>
      
          </div>
        )
      } else {
      return (
        <div className="CodeInput">
          <CodeMirror
            value={question}
            placeholder={placeholders[questionType]}
            theme={oneDark}
            height="auto"
            minHeight="140px"
            viewportMargin="Infinity"
            disabled={disabled}
            onChange={updateCode}
            extensions={[javascript({ jsx: true })]}
          />
        </div>
        );
      }
    }
    }
  }


  function options() {
    if (questionType === 'code') {
      return (
        <div className="athena-request-form-menu d-lg-flex">
          <label htmlFor="athena-request-language" className="dropdown-label">
          Select the code language
          </label>
          <CreatableSelect 
            options={languageOptions} 
            name="athena_request[language]" 
            id="athena-request-language" 
            defaultValue={languageOptions[0]} 
            isClearable={false}  
          />
        </div>
      );
    } 

    if (questionType === 'fix') {
      return (
        <div className="athena-request-form-menu d-flex">
            <input type="checkbox" name="athena_request[code_enhancement]" id="athena-request-code-enhancement" />
            <label htmlFor="athena-request-code-enhancement">💫 Ask Athena to improve this code <span className="text-muted">- optional</span> </label>
        </div>
      )
    }

    return null;
  }


  function submit() {
    setTimeout(function () {
      setDisabled(true);
    }, 0);
  }

  function submitLabel() {
    if (questionType === 'interview') {
      return "Simulate Job Interview";
    } else {
      if (questionType === 'test') {
        return "Start Test";
      } else {
        return "Ask Athena";
      }
    }
  }

  function button() {
    return <input type="submit" name="commit" value={submitLabel()} className="btn btn-branding shadow w-100" disabled={!valid() || disabled} onClick={submit} />

  }

  return (
    <div className="athena-request-form">
      <div className="mb-3 text required athena_request_question" onClick={showErrorMessage}>
        <ul className="athena-request-form-types">
          <li className={questionType === 'question' ? 'active' : null} onClick={() => setQuestionType("question")}>Ask a technical question</li>
          <li className={questionType === 'fix' ? 'active' : null} onClick={() => setQuestionType("fix")}> Fix my code</li>
          <li className={questionType === 'code' ? 'active' : null} onClick={() => setQuestionType("code")}> Write me code</li>
          <li className={questionType === 'explain' ? 'active' : null} onClick={() => setQuestionType("explain")}> Explain this code</li>
          <li className={questionType === 'test' ? 'active' : null} onClick={() => setQuestionType("test")}> Test me</li>
          <li className={questionType === 'interview' ? 'active' : null} onClick={() => setQuestionType("interview")}> Interview me</li>
        </ul>
        <div className="shadow-smooth">
          {textInput()}
          <div className="d-lg-flex  athena-request-form-options justify-content-between">
            <div>
              {options()}
            </div>
            <div>
              {button()}
            </div>
          </div>
        </div>
      </div>

      <input type="hidden" name="athena_request[question_type]" value={questionType} />
      <input type="hidden" name="athena_request[question]" value={question} />
      <div className="d-flex justify-content-center justify-content-lg-between">
        <div>{hint()}</div>
        
      </div>
    </div>
  );
}
