import React, { useState } from "react"
import axios from 'axios';
import CatLoader from './CatLoader';
import Cat from './Cat';
import InfiniteScroll from 'react-infinite-scroller';

export default function Cats(props) {
	const [cats, setCats] = useState(props.cats);
	const [page, setPage] = useState(2);

	function showCats(response) {
		setPage(page + 1);
		if (response.data.length > 0) {
			setCats([...cats, ...response.data])
		}
	}

	function loadMore() {
		axios.get(`${props.load_more_url}?page=${page}&sort=${props.sort}`).then(showCats)
	}

	return (
		<div className="Cats">
			<InfiniteScroll
			    pageStart={1}
			    loadMore={loadMore}
			    hasMore={cats.length !== props.total_count}
			    loader={<CatLoader />}
			    className="row"
			>

				{cats.map((cat) => {
					return (
						<div className="col-md-4" key={cat.id}>
							<Cat cat={cat} />
						</div>
					)
				})}
			</InfiniteScroll>
		</div>
	)
}
