import React from 'react';

export default function Loader(props) {
	return (
		<div className="Loader" key={1}>
			<span className="Loader-emoji Loader-emoji--first">
			  👩🏿‍💻 
			</span>
			<span className="Loader-emoji Loader-emoji--second">
			  👩🏽‍💻
			</span>
			<span className="Loader-emoji Loader-emoji--third">
				👩🏻‍💻
			</span>
			<span className="Loader-emoji Loader-emoji--fourth">
				👩‍💻
			</span>
			<span className="Loader-emoji Loader-emoji--fifth">
			  👩🏾‍💻
			 </span>
		</div>
	)
}

