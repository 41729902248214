import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ClipboardButton(props) {
	const [label, setLabel] = useState(props.label);

	function handleCopy(event) {
		setLabel("Copied");
	}

	return (
		<div className={props.classNames}>
			<CopyToClipboard text={props.content} onCopy={handleCopy}>
				<div>
					{label}
				</div>
			</CopyToClipboard>
		</div>
	);
}