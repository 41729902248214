import React, { useState, useEffect } from 'react';
import Plyr from 'plyr';
import axios from 'axios';
import Countdown from "./Countdown";

export default function Replay(props) {
	const [player, setPlayer] = useState(null);
	const [state, setState] = useState("checkingIn");
	const [name, setName] = useState(props.name);
	const [email, setEmail] = useState(props.email);
	

	useEffect(() => {
		let options = {
			"controls": ['fullscreen', 'volume', 'settings', 'mute'], 
			"clickToPlay": false,
			"autopause": false, 
			"muted": false
		}
		let player = new Plyr('#video-player', options )
		player.volume = 0;
		player.on('ended', event => {
		  next();
		});
		setPlayer(player)
	}, []);

	function changeName(event) {
		setName(event.target.value);
	}

	function changeEmail(event) {
		setEmail(event.target.value);
	}

	window.onbeforeunload = (e) => {
		if (state === 'playing' || state === 'welcoming' || state === 'checkingIn') {
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Do you want to leave the class?	';
      }

      // For Safari
      return 'Do you want to leave the class?';
		}
  }

  function checkIn(event) {
  	event.preventDefault()
  	axios.post(props.create_attendee_path, { 
  		webinar_attendee: {
  			email: email, 
  			first_name: name, 
  			time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
  		}
  	})
  	setState("welcoming");
		player.play();
		player.pause();
  	player.volume = 0;
  }

  function onboardingScreen() {
  	if (state === 'checkingIn') {
	  	return (
	  		<div className="check-in-replay">
	  			<form onSubmit={checkIn}>
	  				<img src={props.logo} className="logo" />
	  				<p>
	  					Complete your registration
	  					<small>
	  					For your 60-minute coding class
	  					</small>

	  				</p>
	  				<div className="form-group">
		  				<label>Your first name*</label>
		  				<input type="text" className="form-control" autoFocus={true} required={true} onChange={changeName} value={name} />
	  				</div>
	  				<div className="form-group">
		  				<label>Your email*</label>
		  				<input type="email" className="form-control" required={true} onChange={changeEmail} value={email}/>
	  				</div>
	  				<button className="btn btn-primary shadow" type="submit">Join SheCodes Express</button>
	  			</form>
	  		</div>
	  	)
  	}

		if (state === 'welcoming') {
			let now = new Date().getTime();
			if (props.time > now) {
				return (
					<div className="welcoming">
						<Countdown time={props.time} students={props.students} logo={props.logo} date={props.date} messages={props.messages} onComplete={play} soundCheck={props.soundCheck} count={props.count} />
					</div>
				)
			} else {
				const iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
				if ((props.time + 45 * 1000 * 60) > now ) {
					if (iOS) {
						return (
							<div className="welcoming">
								<Countdown time={now + 1000 * 15} students={props.students} logo={props.logo} date={props.date} messages={props.messages} onComplete={play} soundCheck={props.soundCheck} count={props.count} />
							</div>
						)	
					} else {
						play((now - props.time) / 1000);
					}
				} else {
					return (
						<div className="welcoming">
							<Countdown time={now + 1000 * 45} students={props.students} logo={props.logo} date={props.date} messages={props.messages} onComplete={play} soundCheck={props.soundCheck} count={props.count} />
						</div>
					)	
				}
			}
		}
  	
  }

  function fullScreen() {
  	player.fullscreen.enter();
  }

	function play(time = 0) {
		setState("playing");
		player.currentTime = time;
		player.play();
		player.volume = 1;
	}

	function next() {
		setState("ended");
		window.location.href = props.redirect_url;
	}

	function video() {
		return (
			<div className="content video-wrapper">
				<a href="/" target="_blank">
					<img src={props.logo} className="logo" target="_blank" />
				</a>
				<div className="video-inner rounded shadow border-0">
					<button className="btn btn-primary shadow-sm center mb-5" type="submit" onClick={fullScreen}>
						Go fullscreen
					</button>
					<div id="video-player" className="plyr__video-embed" data-plyr-provider="vimeo" data-plyr-embed-id={props.vimeo_id} poster={props.poster} />
				</div>
				<p className="center mt-5">
					Having issues watching the class? <a href={`${window.location.pathname}?retry=true`} target="_blank">Try reloading the page</a> or Read our FAQ below.
				</p>
				<div className="content content-sm mt-5">
					<div dangerouslySetInnerHTML={{__html: props.faq}}></div>
				</div>

			</div>
		)
	}


	return (
		<div className="Replay">
			<div className={state}>
				{onboardingScreen()}
				{video()}
			</div>
		</div>
	)
}