import React, { useState } from "react";
import Cookies from 'js-cookie';
import axios from 'axios';

export default function Cat(props) {
	const [liked, setLiked] = useState(Cookies.get(props.cat.id) || false);

	function description() {
		if (props.cat.cat_name) {
			return props.cat.cat_name
		} else {
			return <a href="https://www.instagram.com/explore/tags/catcodes/" target="_blank">#catcodes</a>;
		}
	}

	function picture() {
		return (
			<a href={props.cat.redirect_path} target="_blank">
				<img src={props.cat.picture_url} className="img-fluid cat-picture w-100" loading="lazy" />
			</a>
		);
	}

	function like() {
		setLiked(true);
		Cookies.set(props.cat.id, true);
		axios.patch(`/cats/${props.cat.id}/like`);
	}

	function unlike() {
		setLiked(false);
		Cookies.set(props.cat.id, false);
		axios.patch(`/cats/${props.cat.id}/unlike`);
	}


	function vote() {
		if (liked) {
			return <i className="fas fa-heart liked" onClick={unlike}></i>;
		} else {
			return <i className="far fa-heart" onClick={like}></i>;
		}

	}

	function owner() {
		return (
			<div className="cat-owner">
				<div className="d-flex">
					<img src={props.cat.avatar_url} className="cat-owner-avatar" />
					<div className="cat-owner-details">
						<div className="cat-owner-name">
							<a href={`https://www.instagram.com/${props.cat.instagram_username}`} target="_blank">
								{props.cat.full_name}
							</a>
						</div>
						<div className="cat-owner-hashtag">
							{description()}
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="Cat">
			<div className="cat-preview">
				{picture()}
				<div className="cat-footer d-flex justify-content-between">
					{owner()}
					{vote()}
				</div>
			</div>
		</div>
	)
}